import { defineAsyncComponent } from 'vue'

export default {
  methods: {
    openLoginPopup(orugaModalOptions = {}) {
      this.$tealium.track({ event_name: 'login_popup' })
      const FormLogin = defineAsyncComponent(() =>
        import('~/components/FormLogin.vue')
      )
      this.$oruga.modal.open({
        parent: this,
        animation: 'ucrs-modal-right-',
        contentClass: 'ucrs-modal__container--right overflow-y-auto',
        width: 'auto',
        component: FormLogin,
        onCancel: () => {
          this.$tealium.track({ event_name: 'login_popup_close' })
        },
        props: {
          inModal: true,
          closeOnSuccess: true,
        },
        ...orugaModalOptions,
      })
    },
  },
}
