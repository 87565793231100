<template>
  <div>
    <div class="container">
      <div class="h-22 flex items-center justify-between">
        <Breadcrumbs
          :items="breadcrumbs"
          :label="breadcrumbsLabel"
          :url="breadcrumbsUrl"
        />
        <SvgIcon
          name="share"
          role="button"
          tabindex="0"
          aria-label="Apri menù condivisione"
          :aria-expanded="show"
          :aria-controls="`ShareRegion-${_uid}`"
          class="max-w-9.6 max-h-11 cursor-pointer outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-blue"
          @keyup.enter="show = !show"
          @click="show = !show"
        />
      </div>
    </div>
    <ShareAccordion :region-id="`ShareRegion-${_uid}`" :show="show" />
  </div>
</template>

<script>
// Components
import Breadcrumbs from '~/components/Breadcrumbs'
import ShareAccordion from '~/components/ShareAccordion'

export default {
  components: {
    Breadcrumbs,
    ShareAccordion,
  },
  props: {
    breadcrumbs: {
      type: Array,
      default() {
        return []
      },
    },

    breadcrumbsLabel: {
      type: String,
      default: 'text',
    },

    breadcrumbsUrl: {
      type: String,
      default: 'href',
    },
  },

  data() {
    return {
      show: false,
    }
  },
}
</script>
