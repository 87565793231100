<template>
  <div ref="IntersectionRef">
    <slot v-if="isVisible" />
    <slot v-else name="placeholder" />
  </div>
</template>

<script>
export default {
  props: {
    defaultVisible: {
      type: Boolean,
      defualt: false,
    },
    rootElement: {
      type: Object,
      default: null,
    },
    visibilityOffset: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      isVisible: this.defaultVisible,
      observer: null,
    }
  },

  mounted() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const element = entries[0]
        if (window.requestIdleCallback) {
          window.requestIdleCallback(
            () => {
              if (element.isIntersecting) {
                this.isVisible = true
              }
            },
            { timeout: 600 }
          )
        } else if (element.isIntersecting) {
          this.isVisible = true
        }
      },
      {
        root: this.rootElement ?? null,
        rootMargin: `${this.visibilityOffset}px 0px`,
      }
    )

    this.observer.observe(this.$refs.IntersectionRef)
  },

  unmounted() {
    this.observer?.unobserve(this.$refs.IntersectionRef)
  },
}
</script>
