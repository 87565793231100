<template>
  <NuxtImg
    :src="`/assets/img/logo/${logo.img}`"
    alt="Logo UniCredit RE Services"
    :class="size && `ucrs-logo--${size}`"
    :width="logo.width"
    :height="logo.height"
    format="webp"
    :loading="!preload ? 'lazy' : null"
  />
</template>

<script>
// Static assets
import LogoTypes from '~/assets/js/logo/types'
import Logo from '~/assets/js/logo/images'

export default {
  props: {
    type: {
      type: String,
      default: 'color',
    },

    size: {
      type: String,
      default: null,
    },

    preload: {
      type: Boolean,
    },
  },

  head() {
    const { preload, logo } = this

    if (!preload) {
      return
    }
    return {
      links: [
        {
          rel: 'preload',
          as: 'image',
          href: this.$img(`/assets/img/logo/${logo.img}`),
          fetchpriority: 'high',
        },
      ],
    }
  },

  computed: {
    logo() {
      const { type } = this

      return Logo[type] || Logo[LogoTypes.Color]
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/logo';
</style>
