<template>
  <div class="py-20">
    <template v-if="showSuccess">
      <div class="py-40">
        <SvgIcon name="check" class="text-green w-40 h-40 mx-auto" />
        <h2 class="mt-12 text-3xl font-bold text-center">
          Ricerca salvata correttamente
        </h2>
      </div>
    </template>
    <template v-else>
      <h2 class="h2 mb-4 text-center">Salva ricerca</h2>
      <ValidationObserver
        v-slot="{ errors }"
        ref="Observer"
        class="grid md:grid-cols-2 gap-8 mt-10"
        tag="form"
        @submit.prevent="onFormSubmit"
      >
        <InputWithValidation
          :class="{
            'md:col-span-2': !userLogged,
          }"
          :errors="errors"
          :value="title"
          label="Titolo"
          name="title"
          :placeholder="defaultTitle"
          @input="title = $event"
        />
        <InputWithValidation
          v-if="!userLogged"
          :errors="errors"
          :value="email"
          label="Email *"
          name="email"
          placeholder="Email"
          rules="required|email"
          @input="email = $event"
        />
        <DropdownWithValidation
          :errors="errors"
          :options="notifications"
          :show-placeholder-option="false"
          :value="notification"
          field="label"
          label="Frequenza invio"
          name="notification"
          placeholder="Frequenza invio"
          rules="required"
          @select="notification = $event"
        />
        <div
          class="flex flex-col gap-6 mt-10 md:col-end-3 md:flex-row md:justify-end"
        >
          <UcrsButton type="secondary" @click="$emit('close')"
            >Annulla</UcrsButton
          >
          <UcrsButton type="primary" native-type="submit">Salva</UcrsButton>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
// Mixins
import Auth from '~/mixins/auth'

// Components
import UcrsButton from '~/components/UcrsButton.vue'
import DropdownWithValidation from '~/components/DropdownWithValidation.vue'
import InputWithValidation from '~/components/InputWithValidation.vue'

// Static assets
import { Notifications } from '~/assets/js/search'

export default {
  components: {
    UcrsButton,
    DropdownWithValidation,
    InputWithValidation,
  },
  mixins: [Auth],
  props: {
    section: {
      type: Object,
      default() {
        return null
      },
    },

    filter: {
      type: Object,
      default() {
        return null
      },
    },

    defaultTitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      title: null,
      email: null,
      notification: Notifications[1],
      notifications: Notifications.filter((it) => it.value),
      showSuccess: false,
    }
  },

  computed: {
    userLogged() {
      return this.$store.state.auth.loggedIn
    },

    payload() {
      const { title, email, notification, section, filter, defaultTitle } = this

      const url = this.$route.fullPath
      const frequency = notification.value

      const payload = {
        title: title || defaultTitle,
        email,
        url,
        frequency,
        filter: {
          ...filter,
          section: section.id,
        },
      }

      return payload
    },
  },

  methods: {
    async onFormSubmit() {
      const { Observer } = this.$refs

      await Observer.validate()

      if (Observer.flags.invalid) {
        return
      }

      this.$tealium.track({ event_name: 'salva_ricerca' })

      const { userLogged } = this

      if (userLogged) {
        await this.saveSearch()
        return
      }

      const { payload } = this

      this.$store.dispatch('interactions/setSearch', {
        time: Date.now(),
        ...payload,
      })

      this.openLoginPopup({
        events: {
          success: this.onLoginSuccess,
        },
      })
    },

    async onLoginSuccess() {
      try {
        await this.saveSearch()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },

    async saveSearch() {
      const email = this.$store.state.auth.user.email
      const payload = {
        ...this.payload,
        // We replace the email with the logged user one.
        email,
      }

      const res = await this.$axios.post('/_api/api/v2/saved-searches', payload)

      if (res.status !== 201) {
        throw new Error('Errore nel salvataggio della ricerca')
      }

      this.showSuccess = true

      const savedSearchesCount = res.data.count
      this.$authUtilities.updateUserCount('saved_searches', savedSearchesCount)
      this.$store.dispatch('interactions/setSearch', null)

      setTimeout(() => {
        this.$emit('close')
        this.$router.push('/area-riservata/dashboard')
      }, 2000)
    },
  },
}
</script>
