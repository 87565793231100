var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ucrs-modal__inner"},[_c('div',{staticClass:"ucrs-modal__header"},[_c('div',{staticClass:"ucrs-modal__logoWrapper"},[_c('Logo',{attrs:{"type":_vm.$options.LogoTypes.NoPayoff}})],1),_vm._v(" "),_c('button',{staticClass:"ucrs-modal__close",attrs:{"aria-label":"Chiudi modale"},on:{"click":_vm.close,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.close.apply(null, arguments)}}})]),_vm._v(" "),_c('div',{class:[
      'ucrs-modal__content',
      {
        'ucrs-modal__content--white': _vm.bgColor === 'white',
        'ucrs-modal__content--p-medium': _vm.padding === 'medium',
      },
    ]},[_c('div',{staticClass:"ucrs-modal__component-inner",class:_vm.contentClass},[(_vm.component)?_c(_vm.component,_vm._g(_vm._b({tag:"component",on:{"close":_vm.close}},'component',_vm.componentProps,false),_vm.componentEvents)):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }