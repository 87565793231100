var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:[
    'sticky top-headerHeight bg-white py-4 px-6 md:px-16 md:py-6 border-b-1 border-gray-lighter z-30',
    {
      'lg:top-headerHeightDesktop': !_vm.subnav,
      'lg:top-headerHeightDesktopSubnav': _vm.subnav,
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }