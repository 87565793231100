import LogoTypes from './types.js'

const LogoWhite = 'ucrs-logo-white.svg'
const LogoGray = 'ucrs-logo-gray.svg'
const LogoNoPayoff = 'ucrs-logo-white-no-payoff.svg'
const LogoColor = 'ucrs-logo.svg'

export default {
  [LogoTypes.White]: {
    img: LogoWhite,
    width: '280',
    height: '37',
  },
  [LogoTypes.Gray]: {
    img: LogoGray,
    width: '260',
    height: '40',
  },
  [LogoTypes.NoPayoff]: {
    img: LogoNoPayoff,
    width: '270',
    height: '23',
  },
  [LogoTypes.Color]: {
    img: LogoColor,
    width: '280',
    height: '37',
  },
}
