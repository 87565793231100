<template>
  <section
    :class="[
      'sticky top-headerHeight bg-white py-4 px-6 md:px-16 md:py-6 border-b-1 border-gray-lighter z-30',
      {
        'lg:top-headerHeightDesktop': !subnav,
        'lg:top-headerHeightDesktopSubnav': subnav,
      },
    ]"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    subnav: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
