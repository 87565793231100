var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OField',{attrs:{"label":_vm.label,"title":_vm.value}},[_c('ODropdown',{ref:"Dropdown",on:{"active-change":_vm.onActiveChange},scopedSlots:_vm._u([{key:"trigger",fn:function({ active }){return _c('button',{class:[
        'ucrs-input',
        {
          'ucrs-input--placeholder': !_vm.value,
          'ucrs-input--focused': active,
        },
      ],attrs:{"type":"button"}},[_c('span',{staticClass:"ucrs-input__inner"},[_vm._v(" "+_vm._s(_vm.value || _vm.placeholder)+" ")]),_vm._v(" "),_c('SvgIcon',{staticClass:"ml-2 o-drop__trigger-icon",attrs:{"name":active ? 'angle-up-blue' : 'angle-down'}})],1)}}])},[_vm._v(" "),_c('ClientOnly',[_c('div',{staticClass:"flex flex-col py-10 px-6"},[_vm._t("default")],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }