export function BreadcrumbsSchema(breadcrumbs, $config, $route) {
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  }

  breadcrumbs.forEach((item, idx) => {
    const { url, label } = item

    const id = `${$config.baseURL}${url || $route.path}`

    const listElement = {
      '@type': 'ListItem',
      position: idx + 1,
      item: {
        '@id': id,
        name: label,
      },
    }

    schema.itemListElement.push(listElement)
  })

  return schema
}
