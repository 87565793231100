<template>
  <VisualHero :image="image" preload background-color="bg-blue-listing">
    <slot slot="title" name="title" />
    <slot slot="description" name="description" />
  </VisualHero>
</template>

<script>
// Static assets
import { Sections } from '~/assets/js/sections'

// Components
import VisualHero from '~/components/VisualHero'

const SectionsImages = {
  [Sections.NuoveCostruzioni]:
    '/assets/img/visual/ucrs-visual-nuovecostruzioni.png',
  [Sections.ImmobiliCommerciali]:
    '/assets/img/visual/ucrs-visual-immobilicommerciali.png',
  [Sections.ImmobiliDiPregio]:
    '/assets/img/visual/ucrs-visual-immobilidipregio.png',
  [Sections.ProprietaUnicredit]:
    '/assets/img/visual/ucrs-visual-proprieta-unicredit.png',
  [Sections.VenditaImmobili]:
    '/assets/img/visual/ucrs-visual-venditaimmobili.png',
  default: '/assets/img/visual/ucrs-visual-venditaimmobili.png',
}

export default {
  components: {
    VisualHero,
  },

  props: {
    section: {
      type: String,
      required: true,
    },
  },

  computed: {
    image() {
      const { section } = this

      if (!section) {
        return SectionsImages.default
      }

      return SectionsImages[section]
    },
  },
}
</script>
